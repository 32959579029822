import { Controller } from "@hotwired/stimulus";
import {Bizum, confirmPayment, PaymentRequest, CardInput, createToken} from "@monei-js/components"

export default class extends Controller {
  static targets = ["bizumContainer", "cardInput", "errorMessage", "cardError", "paymentButton", "paymentForm", "paymentRequestContainer"];

  connect() {
    this.initializeBizum();
    this.initializeCardInput();
    this.initializePaymentRequest();
  }
  
  moneiTokenHandler(token, paymentId) {
      return confirmPayment({ paymentId: paymentId, paymentToken: token })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  initializeBizum() {
    const paymentId = this.data.get("paymentId");
    const bizumContainer = this.bizumContainerTarget;
    const errorMessage = this.errorMessageTarget;
    const moneiTokenHandler = this.moneiTokenHandler;

    const bizum = Bizum({
      paymentId: paymentId,
      onSubmit(result) {
        moneiTokenHandler(result.token, paymentId);
      },
      onError(error) {
        errorMessage.innerText = "Ha ocurrido un error al procesar el pago. Por favor, inténtalo de nuevo o contacta con nosotros.";
      },
    });

    bizum.render(bizumContainer);
  }

  initializeCardInput() {
    const paymentId = this.data.get("paymentId");
    const container = this.cardInputTarget;
    const errorText = this.cardErrorTarget;
    const moneiTokenHandler = this.moneiTokenHandler;

    const cardInput = CardInput({
      paymentId: paymentId,
      onChange: (event) => {
        if (event.isTouched && event.error) {
          container.classList.add("is-invalid");
          errorText.innerText = event.error;
        } else {
          container.classList.remove("is-invalid");
          errorText.innerText = "";
        }
      },
    });

    cardInput.render(container);

    this.paymentFormTarget.addEventListener("submit", (event) => {
      event.preventDefault();
      this.paymentButtonTarget.disabled = true;

        createToken(cardInput)
        .then((result) => {
          if (result.error) {
            container.classList.add("is-invalid");
            this.paymentButtonTarget.disabled = false;
            errorText.innerText = result.error;
          } else {
            moneiTokenHandler(result.token, paymentId);
          }
        })
        .catch((error) => {
          this.paymentButtonTarget.disabled = false;
          this.errorMessageTarget.innerText = "Ha ocurrido un error al procesar el pago. Por favor, inténtalo de nuevo o contacta con nosotros.";
        });
    });
  }

  initializePaymentRequest() {
    const paymentId = this.data.get("paymentId");
    const paymentRequestContainer = this.paymentRequestContainerTarget;
    const moneiTokenHandler = this.moneiTokenHandler;


    const paymentRequest = PaymentRequest({
      paymentId: paymentId,
      onSubmit(result) {
        moneiTokenHandler(result.token, paymentId);
      },
      onError(error) {
        console.log(error);
      },
    });

    paymentRequest.render(paymentRequestContainer);
  }
}
