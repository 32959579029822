import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = ["submit"]

  change(event) {
    this.submitTargets.forEach((element) => {
      switch (element.id) {
        case `submit-${event.target.value}`:
          element.classList.remove("hidden")
          break;
        case 'submit-general':
          if (event.target.value) {
            element.disabled = false
            element.type = "submit"
          } else {
            element.disabled = true
          }
          break;
        default:
          element.classList.add("hidden")
          break;
      }
    });
  }
}